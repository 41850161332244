import React from "react"
import Layout from "../components/layout"
import IFrame from "react-iframe"
import styled from "styled-components"
import EllenTopPic from "../images/ellen-armstrong-pic-top.jpg"
import EllenPic1 from "../images/ellen-armstrong-pic-1.jpg"
import EllenPic2 from "../images/ellen-armstrong-pic-2.jpg"
import EllenPic3 from "../images/ellen-armstrong-pic-3.jpg"
import EllenPic4 from "../images/ellen-armstrong-pic-4.jpg"
import EllenPic5 from "../images/ellen-armstrong-pic-5.jpg"

const VideoLayerContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  .iframeContainer {
    height: 90vh;
    width: 40vw;
    border: none;
    background-color: #af4d3b;
    display: grid;
    padding-left: 150px;
    iframe {
      border: none;
      place-self: center;
      @media (max-width: 720px) {
        width: 100%;
      }
    }
    @media (max-width: 1360px) {
      padding-left: 60px;
    }
    @media (max-width: 1024px) {
      padding: 10px;
      height: 400px;
      width: 100%;
      margin-top: 100px;
    }
  }

  .textContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 100px;

    @media (max-width: 720px) {
      padding: 25px;
    }

    .text {
      font-family: "Lato";
      font-size: 18px;
      color: #212028;
      letter-spacing: 2px;

      .imgRight {
        box-sizing: border-box;
        padding: 10px;
        float: right;
        height: 300px;
        @media (max-width: 720px) {
          height: 150px;
        }
      }
      .imgLeft {
        box-sizing: border-box;
        padding: 10px;
        float: left;
        height: 300px;
        @media (max-width: 720px) {
          height: 150px;
        }
      }
    }
  }
  .header {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 1440px;
    padding: 150px 150px 0px 150px;

    .subtitle {
      font-size: 18px;
      color: #c84836;
      font-family: "Lato";
    }

    .title {
      font-size: 32px;
      color: #212028;
      font-family: "Chapaza";

      @media (max-width: 1360px) {
        font-size: 28px;
      }
    }
    @media (max-width: 1024px) {
      padding: 20px;
      margin-top: 0px;
    }
  }
  .mainTextContainer {
    padding: 20px;
  }
`

const EllenArmstrong = () => {
  return (
    <Layout stacked={false}>
      <VideoLayerContainer>
        <div className="iframeContainer">
          <IFrame
            url="https://player.vimeo.com/video/868521824?h=472ea3811b"
            allowFullScreen={true}
            height="360"
            width="640"
          />
        </div>
        <div className="mainTextContainer">
          <div className="header">
            <div className="subtitle">About</div>
            <div className="title">Ellen Armstrong</div>
          </div>
          <div className="textContainer">
            <div className="text">
              <p>
                Ellen Mae Eaton was born April 18, 1934, to an impoverished
                family during the depths of the Great Depression. She rose from
                hardscrabble life on a desolate homestead in Wheatland, Wyoming,
                and a poor but loving childhood in Chadron, Nebraska, to
                national centers of influence in Washington, D.C.
              </p>
              <p>
                Politics was part of Ellen Armstrong’s life from the day she
                married Bill in 1962 – just a week after he became the youngest
                nominee in history for the Colorado State House. They learned
                the ropes together over the next 25 years. Ellen became a
                beloved presence at political events in Colorado for six
                decades, known for a genuine, friendly, and caring spirit.
              </p>
              <p>
                During the 18 years her husband served in the U.S. House of
                Representatives and U.S. Senate, Ellen raised their two
                children, Anne and Wil. At the same time, she became a leader of
                numerous charitable organizations, mentor to hundreds of young
                staffers, founder of congressional Bible study groups, and
                friend of some of the nation’s most powerful leaders. Ellen was
                involved in the National Federation for Drug Free Youth (the
                bi-partisan congressional wives’ group), the Republican
                Congressional Women’s Club, and was an officer in the
                Congressional Wives Prayer Group. She served as a member of the
                International Club, a group of spouses of senators, cabinet
                members, ambassadors, and reporters.
              </p>
              <p>
                In 1976, Ellen chaired the annual first lady’s congressional
                luncheon and later became friends with Betty Ford, Rosalyn
                Carter, Nancy Reagan, and Barbara Bush. For 18 years, she was an
                active member of the Friday Group Bible study group in the home
                of Joann Kemp, wife of Congressman, Cabinet Member, and
                Vice-Presidential nominee Jack Kemp.
              </p>
              <p>
                Ellen also hosted similar Bible study groups in her own homes,
                in both Washington and Denver. In the 1980s, she helped start
                the bipartisan Senate Spouses prayer breakfast, which is still
                active today. During this time, she told friends that as her
                faith grew, she was energized by a passion to share the Gospel
                with others.
              </p>
              <p>
                She became heavily involved in the Senate Spouses Club, a Red
                Cross unit founded in 1917 that hosted blood drives on Capitol
                Hill and made clothes for newborn babies and indigent mothers.
                Ellen later served on the advisory committee of the Susan B.
                Anthony List, one of the country’s most influential pro-life
                organizations.
              </p>
              <p>
                She had a heart for persecuted Christians and Jews all over the
                world, the vulnerable, and the poor, and she traveled widely to
                help shed light on human rights abuses, especially in the Soviet
                Union. Ellen was active in many organizations promoting these
                causes.
              </p>
              <p>
                When her husband retired from the Senate in 1991, he and Ellen
                returned home to Colorado, where she joined the Board of
                Trustees of Colorado Christian University, and the board of
                elders at Cherry Hills Community Church. She left the CCU board
                when her husband was appointed president of the school. But in
                her role as CCU’s “first lady,” she was a constant and faithful
                supporter, booster, and presence at campus events for over a
                decade.
              </p>
              <p>
                Ellen and Bill presided over an unprecedented period of growth
                and realignment at CCU. Her son, Wil, is now the chairman of the
                Board of Trustees at the university.
              </p>
              <p>
                Throughout their lives together, Ellen and Bill Armstrong were
                highly successful in business, and she was a co-owner, officer,
                and board member in numerous businesses: the Colorado Springs
                Sun, Armstrong Broadcasting, Cherry Creek Mortgage, KEZW Radio,
                and others.
              </p>
              <p>
                Bill Armstrong often referred to her as his greatest asset, and
                at their 50th anniversary party, remarked that his “greatest
                success in life was marrying the right woman.” Their marriage
                and love for each other was an inspiration to their family.
              </p>
            </div>
          </div>
          <div className="header">
            <div className="subtitle">In Her Own Words</div>
            <div className="title">My Testimony</div>
            <div className="title">By Ellen Armstrong</div>
          </div>
          <div className="textContainer">
            <div className="text">
              <p>
                Bill, my husband, used to walk on stage and say, &quot;Hi! My
                name is Bill Armstrong, a sinner saved by the blood of
                Jesus.&quot;
              </p>
              <img src={EllenTopPic} className="imgRight" />
              <p>
                Well, my name is Ellen Armstrong, and I, too am a sinner saved
                by the blood of Jesus! But it wasn&#39;t always that way.
              </p>
              <p>
                I don&#39;t like taking things out of context, so will give you
                a &quot;before&quot; sketch of my life. My family lived on farms
                for the first 16 years of my life. The last thing my dad wanted
                to do was go back into town on Sunday for church. We lived
                anywhere from 3 to 20 miles from town. There were no cute little
                country churches dotting the landscape. There was nowhere to go
                except town for church.
              </p>
              <p>
                At 16 I married a young man who worked for my dad breaking
                horses. I was a senior in high school, yet we moved even further
                from town than when I was still with my parents. Fortunately, a
                caring neighbor and his wife invited us to go with them to
                church. Because my husband respected and admired this couple he
                accepted, and we would drive to their place and go with them
                into town.
              </p>
              <p>
                We soon learned we needed to be baptized before joining, and
                both of us complied without knowing anything about what we were
                doing. No classes were required. We attended somewhat regularly,
                but truthfully, found it very boring. The relief was with the
                people, the pastor, his family, and the town people we already
                knew. It was more a social event that took me away from my
                studies for school and &quot;catch-up&quot; work on the farm
                which were my responsibilities.
              </p>
              <img src={EllenPic1} className="imgLeft" />
              <p>
                After a year or so, I had graduated from high school and learned
                my husband had enlisted in the Marine Corps as the Korean War
                was heating up and young men were being drafted into the Army.
                We moved to California where he took basic training, then was
                stationed aboard an aircraft carrier. When his ship embarked for
                the Pacific, I returned to Nebraska. I still didn&#39;t go to
                church unless it was with my older brother and his wife.
              </p>
              <p>
                After leaving the Marine Corps, my husband came home and became
                the manager of a huge ranch for a share of the newborn calves
                each year. I worked in town for North Western Bell Telephone Co.
                in their business office. Our marriage lasted 8 years, then we
                divorced. No children but still a painful time in my life. I
                remember praying for reconciliation, but later wondered if God
                even heard my prayers. I was hoping that all would work out if I
                prayed. So little did I know... certainly, not what God had in
                His plans for me. I had many opportunities to learn in church,
                but it was as if my eyes and ears were closed. Certainly, my
                heart was closed. I was not mad at God, I just moved on as if He
                didn&#39;t care, or maybe, He was never there.
              </p>
              <p>
                In 1959 I left Chadron to become an airline stewardess for
                Western Airlines (now Delta). My first domicile was Salt Lake
                City. I did not entertain the thought of going to church.
                Finally, my request for a transfer to Denver came through, and I
                found my new home. More importantly, I met my husband of 54
                years (we buried him on our 54th anniversary).
              </p>

              <p>What a grand life we had together!</p>
              <p>
                I was privileged to enter Denver University and graduate with
                honors with my class in 1966 and had 2 wonderful children,
                Annie, and Wil. We had 4 years of me in school and he in the
                State Legislature for ten years. We were faithful to take the
                children to church because that is what nice people did. I
                served on the board, attended a Bible study, and we gave
                generously. Many times, I felt uncomfortable doing these things
                as my attitude was resentful of the time it took away from my
                family. I knew it was wrong but couldn&#39;t seem to be cheerful
                about it.
              </p>

              <p>
                Suddenly, another election took us to Washington, D.C. as Bill
                had won a seat from Colorado to the U.S. House of
                Representatives. We moved to a little town across the river in
                Virginia and looked for another church because we somehow had
                sense enough to know it was important. However, we learned
                quickly that politics had reared its head even in church.
              </p>
              <p>
                A certain pastor in Virginia who had a TV audience was urging
                Christians to come out of the woodwork and become vocal and
                vote. Many Christians did not want to be involved in politics as
                it was thought to be ugly and un-Christian for sure. This TV
                Pastor was passionately against abortion, euthanasia, homosexual
                conduct, drugs, and other social issues. He was well ahead of
                the times as those issues are still raging today.
              </p>
              <p>
                This was not what the status quo wanted to hear, so he was
                demonized by the Press which took sides with the opposition.
                Sound familiar? We also heard it from the pulpit. Somehow, this
                did not seem right to us, as we had many concerns about those
                same issues. In the meantime, we had encountered Campus Crusade
                for Christ by way of Bible Studies for women, and many of their
                missionaries had begun contacting Congressmen regarding their
                relationship, or lack of, with Christ. Bible Studies sprung up
                at the White House, the Pentagon, the House and Senate, with
                Ambassadors, and even the press.
              </p>
              <img src={EllenPic2} className="imgLeft" />
              <p>
                Something was happening in Washington D.C. Who would think one
                would meet the Lord in a place like that? God surely must have a
                sense of humor. Maybe, but he surely was at work. Many
                Congressmen and women and later Senators began rethinking their
                faith, and some made a first-time commitment to follow Jesus.
              </p>
              <p>
                <em>
                  &quot;Amazing Grace how sweet the sound that saved a wretch
                  like me. I once was lost, but now am found, was blind, but now
                  I see.&quot;
                </em>
              </p>
              <p>
                I joined all the studies I was invited to: the Fellowship with
                Barbara Priddy, Campus Crusade for Christ with Sallie Clingman
                and Eleanor Page, Community Bible Study with Lee Campbell, and
                The Friday Group with Joanne Kemp in her home studying the
                teachings of Dr. Francis Schaffer of L&#39;Abri, Switzerland.
              </p>
              <p>
                My first teacher was Eleanor Page, a charming, funny, woman who
                asked me at a Bible study in a congressman’s home basement,
                &quot;When did you ask Christ into your life?&quot; I was taken
                aback but said nothing. All through the next week I kept hearing
                myself trying to answer that question. After all, I had minored
                in Religion at Denver University, gone to church regularly,
                served on the board back in Aurora, Colorado, and we gave
                regularly to the church. What more did she think I needed to do?
              </p>

              <p>
                I went back the next week, and before class started, I went to
                her and said, &quot;Eleanor, I don&#39;t know what you are
                talking about.&quot; She smiled at me, her eyes sparkling, and
                said in her sweet, Georgia southern drawl, &quot;I didn&#39;t
                think you did, darlin&#39;. Let me teach you.&quot; She began by
                using the 4 Spiritual Laws, a tract that has scripture
                explaining the essence of the Bible.
              </p>
              <p>
                <ol>
                  <li>
                    God loves you and offers a wonderful plan for your life.
                  </li>
                  <li>
                    Man is sinful and separated from God. Thus, he cannot know
                    and experience God&#39;s love and plan for his life.
                  </li>
                  <li>
                    Jesus Christ is God&#39;s only provision for man&#39;s sin.
                    Through Him you can know and experience God&#39;s love and
                    plan for your life.
                  </li>
                  <li>
                    We must individually receive Jesus Christ as Savior and
                    Lord, then we can know and experience God&#39;s love and
                    plan for our life.
                  </li>
                </ol>
              </p>
              <p>
                Me a sinner? Separated from God? Works don&#39;t count? Saved by
                Grace? Well, that was news to me, but seemed completely right
                all of a sudden. It was as if the sun came out from behind a
                dark bank of clouds. I had never thought of a &quot;personal
                relationship&quot; with God before. Didn&#39;t know how Jesus
                fit into all of this.... so much to learn.
              </p>
              <p>
                But day by day, I became the excited new Christian wanting the
                world to know what a relief it was to know I had a Savior who
                loved me, forgave me, came to earth as man, died for me, and
                wanted me in His fold forever and ever in Heaven.
              </p>
              <img src={EllenPic3} className="imgRight" />
              <p>Oh! What joy.</p>
              <p>
                I rushed home to tell Bill about it. He was mildly interested.
                The next week, I learned more and rushed home again to tell Bill
                what Eleanor had taught me. He politely listened and was
                thoughtful enough to say complementary things. When I told
                Eleanor of his reaction, she sternly told me to stop saying,
                &quot;Eleanor said.” She said, &quot;It is not me; it is what
                the Bible says.&quot;
              </p>
              <p>
                I finally realized Bill may never be able to open the door Jesus
                was knocking on. &quot;Behold! I stand at the door knocking. If
                anyone hears my voice and opens the door, I will come in and eat
                with him and he with me&quot; (Revelation 3:20). Aren&#39;t we
                blessed that Jesus is patient and doesn&#39;t give up? I
                didn&#39;t either and continued to pray for Bill to hear that
                voice saying, &quot;Come home, sinner, come home.&quot;
              </p>
              <p>
                Several months passed and we were out for our Sunday afternoon
                drive looking at grand houses, and wondering how people could
                afford such splendor. Upon arriving back at our modest ranch
                style home (our palace), Bill did not get out of the car. The
                children jumped out, put the garage door up and went inside to
                play with the dog. I asked Bill if something was wrong.
              </p>
              <p>
                He answered, &quot;Well, you are so enthused about your
                spiritual discovery that seems to be all you talk about
                anymore.&quot; I was stunned to think I was doing something that
                displeased him, so said in a remorseful tone, I&#39;m sure,
                &quot;I won&#39;t be doing that anymore. I will stop telling you
                all I have learned.&quot;
              </p>
              <p>
                He said, &quot;No, you misunderstand. I don&#39;t resent you
                telling me these things; it just means you have become the
                spiritual leader of our family, and I know it should be
                me.&quot; I missed a great opportunity right then to have a
                relevant conversation. Instead, I got out of the car in a huff,
                my feelings raw but bound to keep quiet about the subject. Well,
                that lasted about a day and a half because the joy kept bubbling
                to the surface, and I was once again telling him what I was
                learning.
              </p>
              <p>
                I began putting tracts and books in his luggage when he flew to
                Colorado at least three times a month. He never mentioned them
                to me. I still don&#39;t know if he read them or not. But nearly
                3 years later, he began a conversation about his day, and then
                said, &quot;There is something I want to tell you.&quot;
                Suddenly, a cold chill came over me, as I thought, &quot;Here it
                comes. He has found someone else, and he is going to ask me for
                a divorce.&quot; There was so much of it going on among sitting
                Congressmen and Senators. It brought up old memories and I was
                truly frightened.
              </p>
              <img src={EllenPic4} className="imgLeft" />
              <p>
                But as he continued and I began listening, he was telling me of
                an encounter with one of the missionaries from Campus Crusade
                for Christ in his office that day. The man was a Dentist from
                Alabama, Dr. Sam Peeples, recently back from an assignment in
                Asia to work with the Christian Embassy in Washington, a new
                addition to Embassy Row.
              </p>
              <p>
                Dr. Peeples had presented the Gospel to Bill in his office the
                same as Eleanor had done with me 3 years earlier. He was
                patient, kind, and respectful. Bill listened in the same manner.
                Then the bells rang for a vote, and Bill stood up and said,
                &quot;Come with me. I have to go vote. We will walk over to the
                Capitol. I&#39;ll vote and then we&#39;ll continue our
                conversation at dinner in the Sam Rayburn Dining Room” which
                they did.
              </p>
              <p>
                During dinner, Dr. Peeples continued his presentation using the
                Four Spiritual Laws. Finally, he looked at Bill and said
                something like, &quot;Bill, I have told you all I know. Do you
                feel you can accept Jesus as your Savior at this time?&quot;
                Bill said later, &quot;I normally would have said, I will form a
                committee and get back to you.&quot; Instead, he said,
                &quot;Yes, Sam, I think I do.&quot; They prayed and Bill was
                immediately a &quot;born again Christian.&quot; My prayers had
                been answered.
              </p>

              <p>
                Can you imagine my joy when learning what just happened? My
                husband and I would be in Heaven with Jesus someday. We were
                going to raise our children by Christian principles. We would
                love each other the way described in the Bible, and we would
                keep our eyes on Jesus the rest of our lives. Yes, there would
                be and were bumps along the way, but we were never alone going
                over them. And he never asked me for a divorce.
              </p>
              <p>
                &quot;T&#39;was grace that taught my heart to fear and grace my
                fears relieved. How precious did that faith appear, the hour I
                first believed.&quot;
              </p>
              <p>
                Life didn&#39;t change immediately, but it did change. I became
                a better mother, a better wife, Bill more compassionate and
                accepting of people with different opinions. We wanted to do as
                much as we could for the Kingdom of God on Earth, not as works,
                but as gratitude for the great gift we had been given.
              </p>
              <p>
                Bill was like a sponge absorbing and studying the Scriptures. He
                was asked to speak at Governors&#39; breakfasts all over the
                United States. He was honored to be the main speaker at the
                National Prayer Breakfast held once a year in Washington D.C. He
                also gave precious time to Campus Crusade by giving his
                testimony at Executive dinners for them here and abroad. He felt
                his testimony resonated with men, so spoke anywhere and
                everywhere he was called. All this while in the leadership of
                the Senate.
              </p>
              <p>
                After returning home after 18 years in Washington, his speaking
                continued until he was called to lead Colorado Christian
                University for &quot;such a time as this.&quot; That was his
                final assignment on Earth. I know he was welcomed into Heaven
                with words from Jesus, &quot;Well done, good and faithful
                servant.&quot;
              </p>
              <img src={EllenPic5} className="imgLeft" />
              <p>Lesson learned: Keep praying.</p>
              <p>
                I am living in a Senior Home wanting to be an example to others
                around me, that being old and infirm doesn&#39;t mean the Joy is
                gone. Not at all. I see Jesus at work every day in my life. I
                have a loving family who carry on with the assurance of Jesus
                and are celebrating Him together with our loved ones someday.
              </p>
              <p>
                <em>
                  &quot;When we&#39;ve been there ten thousand years bright
                  shining as the Sun, we&#39;ve no less days to sing God&#39;s
                  praise, then when we&#39;d first begun.&quot;
                </em>
              </p>
            </div>
          </div>
        </div>
      </VideoLayerContainer>
    </Layout>
  )
}

export default EllenArmstrong
